import React, { useEffect, useState, useMemo } from "react";
import "./ring-swipe.scss";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import store from "../../redux/store";
import { useNavigate } from "react-router-dom";

// external library
import TinderCard from "react-tinder-card";

// firebase
import {
  addLikeToRing,
  addDislikeToRing,
  addRingToViewedForUser,
  addRingToLikedForUser,
  addRingToDislikedForUser,
  setViewedRingsForUser,
  setLikedRingsForUser,
  fetchRingsByIds,
  analytics,
} from "../../utils/firebase.utils";

// selectors and actions
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { selectLikedRingUrls, selectLikedRings } from "../../redux/rings/rings.selectors";
import { addLikedRing, addLikedRingUrl, setLikedRings } from "../../redux/rings/rings.actions";

// icons
import { ReactComponent as HeartIcon } from "../../assets/heart_outline.svg";
import { ReactComponent as CrossIcon } from "../../assets/tilted_cross.svg";
import { ReactComponent as TagIcon } from "../../assets/sales_tag.svg";
import { ReactComponent as LeftArrowIcon } from "../../assets/left_arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/right_arrow.svg";

// components
import UploaderTag from "../uploader-tag/uploader-tag";
import OverlayModal from "../overlay-modal/overlay-modal";
import RingDetailedView from "../ring-detailed-view/ring-detailed-view";
import ImageButton from "../buttons/image-button";
import { RINGS_URL } from "../../utils/constants.utils";
import { logEvent } from "firebase/analytics";
import { ICurrentUser, IDirection, ILikeDislike, IRing } from "../../utils/interfaces";
import { useTranslation } from "react-i18next";
import { getUrlForAffiliate } from "../../utils/affiliate.utils";

// global vars
let alreadyRemoved: string[] = [];
let localLikedRingsState: {}[] = [];
let handledRing = "";

interface IRingSwipe {
  NoMoreRingsToFetch: boolean;
  addLikedRingUrlState: (ringUrl: string) => void;
  setLikedRingsState: (rings: any) => void;
  addLikedRingState: (ring: any) => void;
  ringsFromDb: IRing[];
  currentUser: ICurrentUser;
  setAllFiltersToTrue: () => void;
  fetchRings: () => void;
}

const RingSwipe = ({
  NoMoreRingsToFetch,
  addLikedRingUrlState,
  setLikedRingsState,
  addLikedRingState,
  ringsFromDb,
  currentUser,
  setAllFiltersToTrue,
  fetchRings,
}: IRingSwipe) => {
  const [rings, setRings] = useState<IRing[]>(ringsFromDb);
  const [onlyTenRings, setOnlyTenRings] = useState<IRing[]>([]);
  const [, setShowHelp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [vh, setVh] = useState(window.innerHeight * 0.01);
  const [isHover, setIsHover] = useState(false);
  const [imageNumber, setImageNumber] = useState(0);
  const [hasBounced, setHasBounced] = useState(false);
  const [country, setCountry] = useState<string | null>(null);
  let navigate = useNavigate();
  const { t } = useTranslation();

   useEffect(() => {
    if(currentUser !== null) return;
    const fetchIpAndCountry = async () => {
      try {
        const response = await fetch("https://getcountryfromip-eult3duyqq-uc.a.run.app");
        const data = await response.json();

        setCountry(data.country);
      } catch (error) {
        console.error("Error fetching IP and country:", error);
      }
    };

    fetchIpAndCountry();
  }, []);

  useEffect(() => {
    const fetchViewedRingsFromUser = async () => {
      const localViewedRings = localStorage.getItem("alreadyRemovedv2")
        ? JSON.parse(localStorage.getItem("alreadyRemovedv2") as string)
        : [];
      let viewedRings = currentUser.viewedRings;

      if (localViewedRings.length > 0) {
        viewedRings = [...viewedRings, ...localViewedRings];
        const uniqueViewedRings: string[] = Array.from(new Set(viewedRings));
        await setViewedRingsForUser(uniqueViewedRings, currentUser.id);
        localStorage.removeItem("alreadyRemovedv2");
      }

      return viewedRings;
    };

    const updateLikedRingsForUser = async () => {
      const localLikedRings = JSON.parse(localStorage.getItem("likedRings") || "[]");
      let usersLikedRings: (string | ILikeDislike)[] = currentUser.likedRings || [];

      if (localLikedRings.length > 0) {
        localStorage.removeItem("likedRings");
        usersLikedRings = [...usersLikedRings, ...localLikedRings];

        const uniqueRingIds: string[] = Array.from(new Set(usersLikedRings.map((ring) => 
          typeof ring === "string" ? ring : ring.id
        )));

        const uniqueUsersLikedRings: string[] = uniqueRingIds.map((id) => 
          usersLikedRings.find((ring) => 
            typeof ring === "string" ? id === ring : id === ring.id
          )
        )
        .filter((ring): ring is string | ILikeDislike => ring !== undefined)
        .map((ring) => typeof ring === "string" ? ring : ring.id);

        await setLikedRingsForUser(uniqueUsersLikedRings, currentUser.id);
        const likedRingsObjects = await fetchRingsByIds(uniqueRingIds);
        setLikedRingsState(likedRingsObjects);
      }
    };

    const fetchData = async () => {
      if (currentUser) {
        if ("viewedRings" in currentUser) {
          alreadyRemoved = await fetchViewedRingsFromUser();
        }
        // If liked rings are in local storage, update them for the current user
        if (localStorage.getItem("likedRings")) {
          await updateLikedRingsForUser();
        }
      }
      // If  aldreadyRemoved rings are in local storage, filter the list of rings
      else if (localStorage.getItem("alreadyRemovedv2")) {
        alreadyRemoved = JSON.parse(localStorage.getItem("alreadyRemovedv2") as string);
      }

      console.log(ringsFromDb);
      
      const filteredRings = ringsFromDb.filter((ring: IRing) => !alreadyRemoved.includes(ring.id));

      setRings(filteredRings);
    };

    fetchData();
  }, [ringsFromDb, currentUser, setLikedRingsState]);

  useEffect(() => {
    setOnlyTenRings(rings.slice(0, 10).reverse());
    if (rings.length < 10 && !NoMoreRingsToFetch) fetchRings();
  }, [rings]);

  const childRefs = useMemo(
    () =>
      Array(10)
        .fill(0)
        .map(() =>
          React.createRef<{
            swipe: (dir: IDirection) => Promise<void>;
            restoreCard: () => Promise<void>;
          }>(),
        ),
    [],
  );

  const outOfFrame = async (direction: string, ringToHandle: IRing) => {
    // setting imageNumber to 0
    setImageNumber(0);

    if (handledRing === ringToHandle.id) return;
    handledRing = ringToHandle.id;
    setShowHelp(false);

    setRings((ringsState: IRing[]) => ringsState.filter((ring) => ring.id !== ringToHandle.id));

    addRingToViewed(ringToHandle.id);
    switch (direction) {
      case "right":
        await addLikedRing(ringToHandle);
        break;
      case "up":
        await addLikedRing(ringToHandle);
        break;
      case "down":
        await addDislikedRing(ringToHandle);
        break;
      case "left":
        await addDislikedRing(ringToHandle);
        break;
      default:
        break;
    }
  };

  const addLikedRing = async (ringToHandle: IRing) => {
    await addLikedRingUrlState(RINGS_URL + ringToHandle.id);
    await addLikedRingState(ringToHandle);
    localLikedRingsState.push({ id: ringToHandle.id, date: Date.now() });
    const _user = (store.getState().user || {}).currentUser;
    await addLikeToRing(ringToHandle.id, _user, country);
    if (_user) await addRingToLikedForUser(ringToHandle.id, _user.id);
    else localStorage.setItem("likedRings", JSON.stringify(localLikedRingsState));
  };

  const addDislikedRing = async (ringToHandle: IRing) => {
    const _user = (store.getState().user || {}).currentUser;
    await addDislikeToRing(ringToHandle.id, _user, country);
    if (_user) await addRingToDislikedForUser(ringToHandle.id, _user.id);
  };

  const addRingToViewed = async (id: string) => {
    const _user = (store.getState().user || {}).currentUser;
    alreadyRemoved.push(id);
    if (_user) await addRingToViewedForUser(id, _user.id);
    else localStorage.setItem("alreadyRemovedv2", JSON.stringify(alreadyRemoved));
  };

  useEffect(() => {
    const arrowKeySwipe = (direction: IDirection) => {
      const cardsLeft = onlyTenRings.filter((ring: IRing) => !alreadyRemoved.includes(ring.id));
      if (cardsLeft.length) {
        const toBeRemoved = cardsLeft[cardsLeft.length - 1]; // Find the card object to be removed
        const index = onlyTenRings.map((ring) => ring).indexOf(toBeRemoved); // Find the index of which to make the reference to
        childRefs[index]?.current?.swipe(direction); // Swipe the card!
      }
    };

    const keySwipe = (event: KeyboardEvent) => {
      const { key } = event;
      switch (key) {
        case "ArrowLeft":
          arrowKeySwipe("left");
          break;
        case "ArrowRight":
          arrowKeySwipe("right");
          break;
        case "ArrowDown":
          arrowKeySwipe("down");
          break;
        case "ArrowUp":
          arrowKeySwipe("up");
          break;
        default:
          break;
      }
    };

    // Only attach the event listener if the modal is not showing
    if (!showModal) {
      window.addEventListener("keydown", keySwipe);
    }

    // Clean up the event listener on component unmount or if the modal state changes
    return () => {
      window.removeEventListener("keydown", keySwipe);
    };
  }, [childRefs, onlyTenRings, rings, ringsFromDb, showModal]);

  const toggleModal = () => {
    setShowModal((oldState) => {
      if (oldState === false)
        window.history.pushState(null, "", `/ring/${onlyTenRings[onlyTenRings.length - 1].id}`);
      else window.history.pushState(null, "", "/");
      return !oldState;
    });
  };

  const swipe = (direction: IDirection, idToBeRemoved: string) => {
    // Filter out any rings that have already been removed
    const cardsLeft = onlyTenRings.filter((ring: IRing) => !alreadyRemoved.includes(ring.id));

    // Only proceed if there are cards left
    if (cardsLeft.length) {
      // Find the card to be removed using the provided id
      const toBeRemoved = cardsLeft.find((ring: IRing) => ring.id === idToBeRemoved);

      // Only proceed if a card to be removed was found
      if (toBeRemoved) {
        // Find the index of the card to be removed in the original array
        const index = onlyTenRings.indexOf(toBeRemoved);

        // Only proceed if the card to be removed was found in the original array
        if (index !== -1) {
          // Swipe the card in the specified direction
          childRefs[index]?.current?.swipe(direction);
        }
      }
    }
  };

  const navigateToRing = () => {
    const isMobile = window.innerWidth <= 768;
    const ring: IRing = onlyTenRings[onlyTenRings.length - 1];

    if (isMobile) {
      navigate(`/ring/${ring.id}`, {
        state: { ring: ring },
      });
    } else {
      toggleModal();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const newVh = window.innerHeight * 0.01;
      setVh(newVh);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const delay = (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const imageNumberForRingWithId = (id: string) => {
    const currentRing: IRing = onlyTenRings[onlyTenRings.length - 1];
    if (currentRing.id !== id) return 0;
    else return imageNumber;
  };

  const isActive = (index: number) => {
    if (index === 9) return "active";
    return "";
  };

  return (
    <>
      {showModal && (
        <OverlayModal close={toggleModal}>
          <RingDetailedView
            ring={onlyTenRings[onlyTenRings.length - 1]}
            close={toggleModal}
            isModal={true}
          />
        </OverlayModal>
      )}
      <div className="swipe-container">
        <div className="card-container" style={{ "--vh": `${vh}px` } as React.CSSProperties}>
          {!rings.length && (
            <div className="card-container__no-more-rings">
              <p>
                {t(
                  "ringSwipe.We currently have no more rings in your chosen category please check back later for new rings!",
                )}
              </p>
              <button className="text-button white" onClick={setAllFiltersToTrue}>
                {t("ringSwipe.Reset filters")}
              </button>
              <p>{t("ringSwipe.Enrich the amount of rings on Rinder by adding your own.")}</p>
              <button className="primary" onClick={() => navigate("/upload-ring")}>
                {t("ringSwipe.Add ring")}
              </button>
            </div>
          )}

          {onlyTenRings.map((ring, index) => (
            <TinderCard
              ref={childRefs[index]}
              key={ring.id}
              onCardLeftScreen={(direction) => outOfFrame(direction, ring)}
            >
              <div className="swipe">
                <div
                  style={
                    {
                      backgroundImage: `url(${
                        Array.isArray(ring.url)
                          ? ring.url[imageNumberForRingWithId(ring.id)]
                          : ring.url
                      })`,
                      "--vh": `${vh}px`,
                    } as React.CSSProperties
                  }
                  className="card"
                >
                  {ring.salesUrl && (
                    <button
                      onMouseEnter={async () => {
                        await delay(200);
                        setIsHover(true);
                      }}
                      onMouseLeave={() => setIsHover(false)}
                      onClick={() => {
                        window.open(
                          getUrlForAffiliate(
                            ring.salesUrl,
                            typeof ring.uploadedBy === "object"
                              ? ring.uploadedBy.id
                              : ring.uploadedBy,
                          ),
                          "_blank",
                        );
                        logEvent(analytics, "generate_lead", {
                          value: ring.salesPrice,
                          currency: ring.currency,
                          vendor: typeof ring.uploadedBy === "object"
                              ? ring.uploadedBy.id
                              : ring.uploadedBy
                        });
                      }}
                      className="card__sales-tag pressable"
                    >
                      <TagIcon className="tag-icon pressable" />
                      <span className={`card__hover-text ${isHover ? "visible" : ""}`}>
                        {t("ringSwipe.visit store")}
                      </span>
                    </button>
                  )}

                  {Array.isArray(ring.url) && imageNumber > 0 ? (
                    <button
                      onClick={() => setImageNumber(imageNumber - 1)}
                      className="previous-image pressable"
                    >
                      <LeftArrowIcon className="arrow-icon pressable" />
                    </button>
                  ) : null}

                  <button
                    onClick={() => swipe("left", ring.id)}
                    className="card__dislike pressable"
                  >
                    <CrossIcon className="cross-icon pressable" />
                  </button>
                  <ImageButton onClick={() => navigateToRing()}>
                    {t("ringSwipe.more info")}
                  </ImageButton>
                  <button onClick={() => swipe("right", ring.id)} className="card__like pressable">
                    <HeartIcon className="heart-icon pressable" />
                  </button>

                  {Array.isArray(ring.url) && ring.url.length - 1 > imageNumber ? (
                    <button
                      onClick={() => setImageNumber(imageNumber + 1)}
                      className={`next-image pressable ${
                        !hasBounced ? "bounce " + isActive(index) : ""
                      }`}
                      onAnimationEnd={() => setHasBounced(true)}
                    >
                      <RightArrowIcon
                        className={`arrow-icon pressable ${
                          !hasBounced ? "bounce " + isActive(index) : ""
                        }`}
                        onAnimationEnd={() => setHasBounced(true)}
                      />
                    </button>
                  ) : null}

                  <UploaderTag
                    userId={
                      typeof onlyTenRings[index]?.uploadedBy === "object"
                        ? (onlyTenRings[index]?.uploadedBy as { id: string; name: string }).id
                        : undefined
                    }
                  />
                </div>
              </div>
            </TinderCard>
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  likedRingUrls: selectLikedRingUrls,
  currentUser: selectCurrentUser,
  likedRingsState: selectLikedRings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addLikedRingUrlState: (ringUrl: string) => dispatch(addLikedRingUrl(ringUrl)),
  addLikedRingState: (ring: IRing) => dispatch(addLikedRing(ring)),
  setLikedRingsState: (rings: IRing[]) => dispatch(setLikedRings(rings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RingSwipe);
