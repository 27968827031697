import { FunctionComponent, useEffect, useState } from "react";
import "./collection-card.scss";
import { IList, IRing } from "../../utils/interfaces";
import { fetchRingsByIds, getRingIdsForList } from "../../utils/firebase.utils";

interface ICollectionCardProps {
  collection: IList;
}

const CollectionCard: FunctionComponent<ICollectionCardProps> = ({ collection }) => {
  const [rings, setRings] = useState<IRing[]>([]);
  const [vh, setVh] = useState(window.innerHeight * 0.01);

  useEffect(() => {
    const getListOfRings = async () => {
      try {
        const ringIds = await getRingIdsForList(collection.id);

        if (ringIds.length > 0) {
          fetchRingsByIds(ringIds).then((result) => {
            setRings(result);
          });
        }
      } catch (error) {}
    };
    getListOfRings();
  }, [collection]);
  return (
    <div className="collection-card__card-container card-container">
      {rings.slice(0, 3).map(({ url, id }, index) => (
        <div
          key={id}
          style={
            {
              backgroundImage: `url(${Array.isArray(url) ? url[0] : url})`,
              "--vh": `${vh}px`,
            } as React.CSSProperties
          }
          className="collection-card__card card"
        >
          {index === rings.length - 1 && (
            <span className="collection-card__title">{collection.name}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default CollectionCard;
