import React, { useEffect } from "react";
import "./toast.scss";

interface ToastProps {
  message: string;
  show: boolean;
  onClose: () => void;
  top?: number;
  left?: number;
}

const Toast: React.FC<ToastProps> = ({ message, show, onClose, top, left }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  return (
    <div className={`toast ${show ? "show" : ""}`} style={{ top, left }}>
      {message}
    </div>
  );
};

export default Toast;
