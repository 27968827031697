import { useCallback, useEffect, useState } from "react";
import "./comments-container.scss";
import {
  addCommentToRingWithId,
  analytics,
  getForRingIdCommentsSubcollection,
  getUserWithId,
} from "../../utils/firebase.utils";
import { IComment, ICurrentUser } from "../../utils/interfaces";
import Spinner from "../spinner/spinner";
import Comment from "../comment/comment";
import { logEvent } from "firebase/analytics";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

export interface ICommentsContainerProps {
  ringId: string;
  currentUser: ICurrentUser;
}

const CommentsContainer = ({ ringId, currentUser }: ICommentsContainerProps) => {
  const [comments, setComments] = useState(Array<IComment>);
  const [isLoading, setIsLoading] = useState(Boolean);
  const [commentInput, setCommentInput] = useState("");
  const [referenceUserId, setReferenceUserId] = useState("");
  const [referenceCommentId, setReferenceCommentId] = useState("");
  const [referenceName, setReferenceName] = useState("");
  const { t } = useTranslation();

  const sortComments = (comments: any[]) => {
    const commentsMap = new Map<string, any>();
    const topLevelComments: any[] = [];

    // Create a map of comments with their IDs
    comments.forEach((comment: any) => {
      commentsMap.set(comment.id, comment);
    });

    // Populate replies for each comment
    comments.forEach((comment: any) => {
      const parentComment = commentsMap.get(comment.referenceCommentId);
      if (parentComment) {
        if (!parentComment.replies) {
          parentComment.replies = [];
        }
        parentComment.replies.push(comment);
      } else {
        topLevelComments.push(comment);
      }
    });

    // Flatten the comments into a single list
    const flattenComments = (comment: any): any[] => {
      let result: any[] = [comment];

      if (comment.replies) {
        comment.replies.forEach((reply: any) => {
          result = result.concat(flattenComments(reply));
        });
        delete comment.replies;
      }

      return result;
    };

    // Start with topLevelComments and flatten the structure
    const sortedComments = topLevelComments.flatMap((comment: any) => flattenComments(comment));

    return sortedComments;
  };

  useEffect(() => {
    setIsLoading(true);
    getForRingIdCommentsSubcollection(ringId).then((subCollection) => {
      setComments(sortComments(subCollection));
      setIsLoading(false);
    });
  }, [ringId]);

  const addComment = useCallback(() => {
    if (!currentUser) return;
    if (!commentInput) return;
    const comment: IComment = {
      message: commentInput,
      createdOn: Date.now(),
      authorId: currentUser.id,
      referenceUserId: referenceUserId,
      referenceCommentId: referenceCommentId,
    };

    addCommentToRingWithId(comment, ringId);

    logEvent(analytics, "add_comment", {
      ringId: ringId,
      userId: currentUser.id,
    });

    setComments([...comments, comment]);
    setCommentInput("");
  }, [
    currentUser,
    commentInput,
    referenceUserId,
    ringId,
    comments,
    setComments,
    setCommentInput,
    analytics,
  ]);

  useEffect(() => {
    const hasMention = commentInput.includes(`@${referenceName}`);

    if (!hasMention) {
      setReferenceUserId("");
      setReferenceCommentId("");
      setReferenceName("");
    }
  }, [commentInput, referenceName]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        addComment();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [addComment]);

  const onReply = async (userId: string, commentId: string) => {
    const user = await getUserWithId(userId);

    setCommentInput(`@${user.displayName} `);
    setReferenceUserId(userId);
    setReferenceCommentId(commentId);
    setReferenceName(user.displayName);
  };

  return isLoading ? (
    <Spinner theme="light" />
  ) : (
    <div className="comments-view">
      <p>
        <strong>
          {t("comments-container.Comments")} ({comments.length}):
        </strong>
      </p>
      <div className="comments-view__comments-container">
        {comments.map((comment, index) => (
          <Comment
            className={comment.referenceUserId && "response"}
            key={index}
            {...comment}
            onReply={onReply}
          />
        ))}
      </div>
      {currentUser && (
        <div className="comments-view__input">
          <div className="comments-view__user-avatar">
            <img
              className="comments-view__user-avatar-image"
              alt={currentUser.displayName}
              src={currentUser.profileImage ? currentUser.profileImage : "/profile-placeholder.png"}
            />
          </div>
          <input
            className="comments-view__input-field"
            placeholder={comments.length > 0 ? "Write a comment..." : "Be the first to comment..."}
            type="text"
            value={commentInput}
            onChange={(e) => setCommentInput(e.target.value)}
          />
          {commentInput && (
            <button
              className="comments-view__submit-button"
              onClick={() => {
                addComment();
              }}
            >
              {t("comments-container.Submit")}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, null)(CommentsContainer);
