import { useEffect, FC, useState } from "react";
import "./collection-viewer.scss";
import RingSwipe from "../../components/ring-swipe/ring-swipe";
import { useLocation } from "react-router-dom";
import {
  fetchRingsByIds,
  getDisplayNameFromId,
  getListWithId,
  getRingIdsForList,
} from "../../utils/firebase.utils";
import { IList, IRing } from "../../utils/interfaces";
import Spinner from "../../components/spinner/spinner";
import BasePage from "../../components/base-page/base-page";

interface ICollectionProps {}

const CollectionViewer: FC<ICollectionProps> = () => {
  const location = useLocation();
  const { collectionId } = location.state || {};
  const [collection, setCollection] = useState<IList | null>(null);
  const [ringIdsFromList, setRingIdsFromList] = useState<string[]>([]);
  const [rings, setRings] = useState<IRing[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayName, setDisplayName] = useState<string | null>(null);

  useEffect(() => {
    const fetchList = async () => {
      let listId = collectionId;
      if (!listId && location.pathname && location.pathname.split("/").length > 2)
        listId = location.pathname.split("/")[2];

      if (!listId) return;

      try {
        // if getRingWithId(ringId) is async, you need to await it
        const ringIds = await getRingIdsForList(listId);

        if (ringIds) {
          setRingIdsFromList(ringIds);
        }

        const collectionData = await getListWithId(listId);

        if (collectionData) {
          setCollection(collectionData as IList);
        }
      } catch (error) {
        console.error("Failed to set list from URL: ", error);
      }
    };
    fetchList();
  }, [location, collectionId]);

  useEffect(() => {
    if (ringIdsFromList.length > 0) {
      fetchRingsByIds(ringIdsFromList).then((result) => {
        console.log("Rings from fetch: ", result);
        setRings(result);
        setIsLoading(false);
      });
    }
  }, [ringIdsFromList]);

  useEffect(() => {
    const fetchDisplayName = async () => {
      if (collection?.createdBy) {
        const name = await getDisplayNameFromId(collection.createdBy);
        setDisplayName(name);
      }
    };

    fetchDisplayName();
  }, [collection?.createdBy]);

  return (
    <BasePage>
      <div className="collection__tag">
        Collection "{collection?.name}" curated by {displayName}
      </div>
      <div className="collection__container">
        <div className="collection__card-container">
          {isLoading ? (
            <Spinner />
          ) : (
            <RingSwipe
              NoMoreRingsToFetch={false}
              ringsFromDb={rings}
              setAllFiltersToTrue={function (): void {}}
              fetchRings={function (): void {}}
            />
          )}
        </div>
      </div>
    </BasePage>
  );
};

export default CollectionViewer;
