import { useEffect, useState } from "react";
import "./user.scss";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

// components
import BasePage from "../../components/base-page/base-page";
import Statistics from "../../components/sidebar/statistics";
import ImageBox from "../../components/image-box/image-box";
import LikedRingsContainer from "../../components/liked-rings-container/liked-rings-container";
import ProfileImageHolder from "../../components/profile-image-holder/profile-image-holder";
import Spinner from "../../components/spinner/spinner";

// selectors and actions
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { setLikedRings } from "../../redux/rings/rings.actions";

// svgs
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as LinkIcon } from "../../assets/add_link.svg";
import { ReactComponent as AddIcon } from "../../assets/add.svg";

// utils
import {
  updatePropForUserWithId,
  getUserWithId,
  updateDisplayNameForRingIds,
  fetchRingsByIds,
} from "../../utils/firebase.utils";
import { compose } from "../../utils/function.utils";
import { selectLikedRings } from "../../redux/rings/rings.selectors";
import { ILikeDislike, IRing } from "../../utils/interfaces";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import CollectionsContainer from "../../components/collections-container/collections-container";

interface IUser {
  setLikedRingsState: (rings: IRing[]) => void;
}

const User = ({ setLikedRingsState }: IUser) => {
  const currentUser = useSelector(selectCurrentUser);
  const likedRingsFromState = useSelector(selectLikedRings);
  const location = useLocation();
  let navigate = useNavigate();
  const [displayName, setDisplayName] = useState("");
  const [userLink, setUserLink] = useState("");
  const [userId, setUserId] = useState("");
  const [nameIsDisabled, setNameIsDisabled] = useState(true);
  const [linkIsDisabled, setLinkIsDisabled] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [uploadedRings, setUploadedRings] = useState<IRing[]>([]);
  const [uploadedRingsIds, setUploadedRingsIds] = useState<string[]>([]);
  const [likedRingIds, setLikedRingIds] = useState<ILikeDislike[] | string[]>([]);
  const [collectionIds, setCollectionIds] = useState<string[]>([]);
  const [likedRings, setLikedRings] = useState<IRing[]>([]);
  const [isHidden, setIsHidden] = useState(true);
  const [userProfileImage, setUserProfileImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLikedRings, setIsLoadingLikedRings] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const pathArray = location.pathname.split("/");
    if (currentUser && pathArray[2] === currentUser.id) {
      setUserId(currentUser.id);
      setDisplayName(currentUser.displayName);
      currentUser.uploadedRings
        ? setUploadedRingsIds(currentUser.uploadedRings)
        : setUploadedRingsIds([]);
      setLikedRingIds(currentUser.likedRings ? currentUser.likedRings : []);
      setCollectionIds(currentUser.lists ? currentUser.lists : []);
      setUserProfileImage(currentUser.profileImage);
      if (currentUser.link) setUserLink(currentUser.link);
      return setShowEdit(true);
    }

    getUserWithId(pathArray[2]).then((user) => {
      if (!user) return;
      setUserId(user.id);
      setDisplayName(user.displayName);
      user.link ? setUserLink(user.link) : setUserLink("");
      setUploadedRings([]);
      user.uploadedRings ? setUploadedRingsIds(user.uploadedRings) : setUploadedRingsIds([]);
      setLikedRingIds(user.likedRings ? user.likedRings : []);
      setCollectionIds(user.lists ? user.lists : []);
      setUserProfileImage(user.profileImage ? user.profileImage : "");

      setShowEdit(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, currentUser?.id]);

  const updateDisplayName = () => {
    if (displayName) setNameIsDisabled(!nameIsDisabled);
    if (displayName.trim() === currentUser.displayName || displayName.trim() === "") return;
    updatePropForUserWithId("displayName", displayName.trim(), currentUser.id);
    updateDisplayNameForRingIds(displayName.trim(), currentUser.uploadedRings);
  };

  const possessiveName = (displayName: string) => {
    const isEnglish = i18n.language === "en";
    const isSwedish = i18n.language === "sv";

    if (isEnglish) {
      return displayName.endsWith("s") ? `${displayName}'` : `${displayName}'s`;
    } else {
      if (isSwedish) {
        return displayName.endsWith("s") ? `${displayName}` : `${displayName}${t("general.s")}`;
      } else {
        return `${displayName}${t("general.s")}`;
      }
    }
  };

  const updateUserLink = () => {
    setLinkIsDisabled(!linkIsDisabled);
    if (userLink === currentUser.link) return;
    updatePropForUserWithId("link", userLink, currentUser.id);
  };

  useEffect(() => {
    const filterOutWaitingForApprovalIfVisitor = (uploadedRings: IRing[]): IRing[] => {
      if (currentUser?.id === userId) return uploadedRings;
      return uploadedRings.filter((ring) => ring.waitingForApproval !== true);
    };

    const sortByUploadedOn = (arr: IRing[]): IRing[] => {
      return arr.sort(
        (a, b) => (b.uploadedOn ? b.uploadedOn : 0) - (a.uploadedOn ? a.uploadedOn : 0),
      );
    };

    const processRings = (uploadedRings: IRing[]) => {
      const sortedAndFilteredRings = compose(
        sortByUploadedOn,
        filterOutWaitingForApprovalIfVisitor,
      )(uploadedRings);

      setUploadedRings(sortedAndFilteredRings);
      setIsLoading(false);
    };

    if (!uploadedRingsIds.length) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    fetchRingsByIds(uploadedRingsIds)
      .then((result: IRing[]) => {
        processRings(result);
      })
      .catch((error) => console.error(error));
  }, [uploadedRingsIds, currentUser, userId]);

  useEffect(() => {
    if (!isItAGuest() && likedRingsFromState.length > 0) return setLikedRings(likedRingsFromState);

    setIsLoadingLikedRings(true);
    if (likedRingIds.length === 0) return setIsLoadingLikedRings(false);

    const uniqueLikedIds = new Set(
      likedRingIds.map((ringId) => (typeof ringId === "object" ? ringId.id : ringId)),
    );

    const uniqueLikedIdsArray = Array.from(uniqueLikedIds);

    fetchRingsByIds(uniqueLikedIdsArray).then((result: IRing[]) => {
      setLikedRings(result);
      setIsLoadingLikedRings(false);
      if (!isItAGuest()) setLikedRingsState(result);
    });
  }, [likedRingIds, likedRingsFromState, setLikedRingsState]);

  const renderDisabledLinkOrAddLink = () => {
    if (userLink) {
      return <a href={userLink}>{userLink}</a>;
    } else {
      return <span onClick={() => setLinkIsDisabled(!linkIsDisabled)}>Add a link</span>;
    }
  };

  const renderInput = () => {
    return (
      <input
        className="text-input"
        type="text"
        onChange={(e) => setUserLink(e.target.value)}
        defaultValue={userLink}
      />
    );
  };

  const isItAGuest = () => currentUser?.id !== userId;

  const isLikedRings = () => window.location.hash === "#liked-rings";

  const isCollections = () => window.location.hash === "#collections";

  const getSelectedTab = () => {
    switch (window.location.hash) {
      case "#liked-rings":
        return likedRingsContainer();
        break;
      case "#collections":
        return collectionsContainer();
        break;
      default:
        return imageContainer();
        break;
    }
  };

  const likedRingsContainer = () => (
    <LikedRingsContainer
      isLoading={isLoadingLikedRings}
      likedRingsParent={likedRings}
      setLikedRingsParent={setLikedRings}
      isGuest={isItAGuest()}
    />
  );

  const imageContainer = () => (
    <div className="user__image-container">
      {isLoading ? (
        <Spinner theme="light" />
      ) : uploadedRings.length ? (
        uploadedRings.map(({ id, url, numberOfLikes, waitingForApproval }) => (
          <ImageBox
            key={id}
            {...{
              id,
              url: Array.isArray(url) ? url[0] : url,
              numberOfLikes,
              waitingForApproval,
              showLikes: true,
              showLikeButton: false,
            }}
          />
        ))
      ) : (
        <>
          {showEdit && !uploadedRings.length && (
            <div className="box empty">
              <button onClick={() => navigate("/upload-ring")} className="box__add-button">
                <AddIcon className="box__add-icon" />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );

  const collectionsContainer = () => <CollectionsContainer userId={userId} />;

  return (
    <BasePage>
      <div className="user">
        <div className="user__header">
          <div className="user__header-row">
            <ProfileImageHolder
              isEdit={showEdit}
              userId={userId}
              userProfileImage={userProfileImage}
            />
            <table>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="text-input"
                      type="text"
                      onChange={(e) => setDisplayName(e.target.value)}
                      defaultValue={displayName}
                      disabled={nameIsDisabled}
                    />
                  </td>
                  <td>
                    {showEdit ? (
                      nameIsDisabled ? (
                        <EditIcon
                          className="user__edit-icon"
                          onClick={() => setNameIsDisabled(!nameIsDisabled)}
                        />
                      ) : (
                        <button onClick={updateDisplayName}>{t("user.save")}</button>
                      )
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    {!userLink && isItAGuest() ? null : (
                      <div className="user__link-box">
                        <LinkIcon className="user__link-icon" />
                        {linkIsDisabled ? renderDisabledLinkOrAddLink() : renderInput()}
                      </div>
                    )}
                  </td>
                  <td>
                    {showEdit ? (
                      linkIsDisabled ? (
                        <EditIcon
                          className="user__edit-icon"
                          onClick={() => setLinkIsDisabled(!linkIsDisabled)}
                        />
                      ) : (
                        <button onClick={updateUserLink}>{t("user.save")}</button>
                      )
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {likedRingIds.length && (
            <>
              <div className={`${isHidden && "hidden"} user__header-row`}>
                <Statistics likedRings={likedRings} />
              </div>
              <div
                className={`${!isHidden && "hidden"} user__header-hidden-gradient`}
                onClick={() => setIsHidden(false)}
              ></div>
            </>
          )}
        </div>
        <div className="user__body">
          <div className="user__body-section">
            <div className="user__body-tabs">
              <h4
                onClick={() => (window.location.hash = "")}
                className={!isLikedRings() && !isCollections() ? "active" : ""}
              >
                {isItAGuest() ? possessiveName(displayName) : t("user.Your")} {t("user.Rings")} (
                {uploadedRings.length > 1 ? uploadedRings.length : uploadedRingsIds.length})
              </h4>
              <h4
                onClick={() => (window.location.hash = "liked-rings")}
                className={isLikedRings() ? "active" : ""}
              >
                {isItAGuest() ? possessiveName(displayName) : t("user.Your")}{" "}
                {t("user.Liked Rings")} ({likedRingIds.length})
              </h4>
              <h4
                onClick={() => (window.location.hash = "collections")}
                className={isCollections() ? "active" : ""}
              >
                {isItAGuest() ? possessiveName(displayName) : t("user.Your")}{" "}
                {t("user.Collections")} ({collectionIds.length})
              </h4>
            </div>
            {getSelectedTab()}
          </div>
        </div>
      </div>
    </BasePage>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLikedRingsState: (rings: IRing[]) => dispatch(setLikedRings(rings)),
});

export default connect(null, mapDispatchToProps)(User);
