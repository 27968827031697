import "./form-input.scss";

interface IFormInput {
  onChange: any
  label: string
  black?: boolean
  value?: any
  name?: string
  type?: string
  autoComplete?: string
  required?: boolean
  min?: number
  max?: number
  step?: number
}

const FormInput = ({ onChange, label, black = false, ...otherProps }: IFormInput) => (
  <div className="group">
    <input className="form-input" onChange={onChange} {...otherProps} />
    {label ? (
      <label
        className={`${otherProps.value || otherProps.value === 0 ? "shrink" : ""} ${
          black && "black"
        } form-input-label`}
      >
        {label}
      </label>
    ) : null}
  </div>
);

export default FormInput;
