import {FunctionComponent, useEffect, useState} from 'react';
import "./collections-container.scss"
import { getListWithId, getUserWithId } from '../../utils/firebase.utils';
import CollectionCard from '../collection-card/collection-card';
import { IList } from '../../utils/interfaces';


interface ICollectionsContainerProps {
    userId: string;
}

const CollectionsContainer: FunctionComponent<ICollectionsContainerProps> = ({userId}) => {
    const [collections, setCollections] = useState<IList[] | null>(null);

    useEffect(() => {
        const fetchCollections = async () => {
            if (!userId) {
                return;
            }
            const user = await getUserWithId(userId);
            if (user && user.lists) {
                const userCollections = await Promise.all(user.lists.map(collectionId => getListWithId(collectionId)));
                setCollections(userCollections.filter((collection): collection is IList => collection !== null));
            }
        };

        fetchCollections();
    }, [userId]);

  return <div className="collections-container">{collections && collections.map((collection) => <CollectionCard key={collection.id} collection={collection} />)}</div>;
};

export default CollectionsContainer;
