import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import {
  getLabelInDataByValue,
  stoneCutTagsData,
  stoneTypeTagsData,
  colorTagsData,
  styleTagsData,
  caratData,
  currencyData,
  metalTagsData,
  settingTagsData,
  NOT_LOGGED_IN,
} from "../../utils/constants.utils";
import ConfettiExplosion from "react-confetti-explosion";
import CreatableSelect from "react-select/creatable";
import { IRing } from "../../utils/interfaces";
import FilterButton from "../filter-button/filter-button";
import { ReactComponent as AddIcon } from "../../assets/add.svg";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";
import { ReactComponent as CrossIcon } from "../../assets/tilted_cross.svg";
import { ReactComponent as LikeIcon } from "../../assets/favorite.svg";
import { useTranslation } from "react-i18next";
import CommentsContainer from "../comments-container/comments-container";
import {} from "../../utils/constants.utils";
import { analytics, updateAttributeOfRingWithIdInCollection } from "../../utils/firebase.utils";
import { logEvent } from "firebase/analytics";
import { Link } from "react-router-dom";
import { getUrlForAffiliate } from "../../utils/affiliate.utils";

interface RingInfoProps {
  ring: IRing;
}

const RingInfo: React.FC<RingInfoProps> = ({ ring }) => {
  const currentUser = useSelector(selectCurrentUser);
  const { t } = useTranslation();
  const [stoneCut, setStoneCut] = useState<string[]>([]);
  const [showAddTag, setShowAddTag] = useState<boolean>(false);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);

  const goToFilteredUrl = (key: string, value: string | number) => {
    const baseUrl = `${window.location.origin}`;

    const newUrl = new URL(baseUrl);

    newUrl.searchParams.set(key, value as string);

    window.open(newUrl.toString(), "_blank");
  };

  const updateRingTags = () => {
    setShowConfetti(false);
    updateAttributeOfRingWithIdInCollection(currentUser.id, "updatedBy", ring.id, "rings");
    updateAttributeOfRingWithIdInCollection(new Date(), "updatedOn", ring.id, "rings");
    updateAttributeOfRingWithIdInCollection(
      [...(ring.stoneCut as string[]), ...stoneCut],
      "stoneCut",
      ring.id,
      "rings",
    );
    setShowAddTag(false);
    ring.stoneCut = [...(ring.stoneCut as string[]), ...stoneCut];
    setTimeout(() => {
      setShowConfetti(true);
    }, 100);
  };

  const getTagsInOrder = (tags: Array<string>) => {
    const sortedTags = tags.flatMap((tag) => {
      const stoneCutLabel = getLabelInDataByValue(stoneCutTagsData, tag);
      const stoneTypeLabel = getLabelInDataByValue(stoneTypeTagsData, tag);
      const colorLabel = getLabelInDataByValue(colorTagsData, tag);
      const styleLabel = getLabelInDataByValue(styleTagsData, tag);
      const metalLabel = getLabelInDataByValue(metalTagsData, tag);
      const settingLabel = getLabelInDataByValue(settingTagsData, tag);

      // Collect all tags with their corresponding labels
      return [
        { label: stoneCutLabel, type: "stoneCut", value: tag },
        { label: stoneTypeLabel, type: "stoneType", value: tag },
        { label: colorLabel, type: "color", value: tag },
        { label: styleLabel, type: "style", value: tag },
        { label: metalLabel, type: "metal", value: tag },
        { label: settingLabel, type: "setting", value: tag },
      ].filter((tag) => tag.label);
    });

    // Define the order of priorities
    const typePriority: Record<string, number> = {
      stoneCut: 1,
      stoneType: 2,
      color: 3,
      setting: 4,
      style: 5,
      metal: 6,
    };

    // Sort the tags based on the defined priorities
    sortedTags.sort((a, b) => typePriority[a.type] - typePriority[b.type]);

    return sortedTags;
  };

  const goToUrl = (
    url: string,
    salesPrice: number | undefined,
    currency: string | undefined,
    vendor: string,
  ) => {
    logEvent(analytics, "generate_lead", {
      value: salesPrice ? salesPrice : 0,
      currency: currency ? currency : "",
      vendor,
    });
    const link = new URL(url);

    window.open(link.toString(), "_blank");
  };

  const getVendorName = (uploadedBy: string | { name: string }) => {
    if (typeof uploadedBy === "object" && uploadedBy !== null) {
      return uploadedBy.name;
    }

    return uploadedBy;
  };

  return (
    <div className="ring-detailed-view__info">
      <button className="ring-detailed-view__save-button">Save</button>

      {showConfetti && (
        <ConfettiExplosion
          force={0.6}
          duration={5000}
          particleCount={200}
          height={1600}
          width={1600}
          zIndex={1000}
        />
      )}
      {Array.isArray(ring.stoneCut) ? (
        <div className="ring-detailed-view__filter-row">
          {getTagsInOrder(ring.stoneCut).map((tag) => (
            <FilterButton
              onClick={() => goToFilteredUrl("tags", tag.value)}
              isActive={true}
              key={`${tag.value}-${tag.type}`}
            >
              {tag.label}
            </FilterButton>
          ))}
          {!showAddTag && currentUser && (
            <button
              onClick={() => setShowAddTag(!showAddTag)}
              className="ring-detailed-view__add-tag"
            >
              <AddIcon />
            </button>
          )}
          {showAddTag && (
            <CreatableSelect
              id="cut-select"
              isMulti
              options={[
                ...stoneCutTagsData,
                ...stoneTypeTagsData,
                ...colorTagsData,
                ...styleTagsData,
                ...metalTagsData,
                ...settingTagsData,
              ]}
              onChange={(e) => setStoneCut(e.map((cut) => cut.value))}
              required
              placeholder="cut, metal, etc"
            />
          )}
          {showAddTag && stoneCut.length > 0 && (
            <button
              onClick={() => updateRingTags()}
              className="ring-detailed-view__add-tag confirm"
            >
              <CheckIcon />
            </button>
          )}
          {showAddTag && stoneCut.length === 0 && (
            <button
              onClick={() => setShowAddTag(false)}
              className="ring-detailed-view__add-tag confirm"
            >
              <CrossIcon />
            </button>
          )}
        </div>
      ) : (
        <div className="ring-detailed-view__filter-row">
          <FilterButton
            onClick={() => goToFilteredUrl("tags", ring.stoneCut as string)}
            isActive={true}
          >
            {getLabelInDataByValue(
              [
                ...stoneCutTagsData,
                ...stoneTypeTagsData,
                ...colorTagsData,
                ...styleTagsData,
                ...metalTagsData,
                ...settingTagsData,
              ],
              ring.stoneCut,
            )}
          </FilterButton>
        </div>
      )}
      <FilterButton
        isActive={true}
        onClick={() => goToFilteredUrl("stoneSize", ring.mainStoneSize)}
      >
        {ring.actualStoneSize
          ? ring.actualStoneSize
          : getLabelInDataByValue(caratData, ring.mainStoneSize)}{" "}
        {t("general.carat")}
      </FilterButton>
      <FilterButton isActive={true} isReverse={true}>
        <LikeIcon className="like-icon" /> {ring.numberOfLikes}
      </FilterButton>
      <FilterButton isActive={true} isReverse={true}>
        {t("general.Rank")} {ring.rank}
      </FilterButton>
      {ring.salesPrice ? (
        <FilterButton
          isActive={true}
          onClick={() =>
            goToUrl(
              getUrlForAffiliate(ring.salesUrl, getVendorName(ring.uploadedBy)),
              ring.salesPrice,
              ring.currency,
              getVendorName(ring.uploadedBy),
            )
          }
        >
          ≈ {ring.currency ? getLabelInDataByValue(currencyData, ring.currency) : "$"}
          {ring.salesPrice}
        </FilterButton>
      ) : (
        <></>
      )}
      <br />
      {ring.salesUrl ? (
        <a
          onClick={() =>
            logEvent(analytics, "generate_lead", {
              value: ring.salesPrice,
              currency: ring.currency,
              vendor: getVendorName(ring.uploadedBy),
            })
          }
          href={getUrlForAffiliate(
            ring.salesUrl,
            typeof ring.uploadedBy === "object" ? ring.uploadedBy.id : ring.uploadedBy,
          )}
          target="_blank"
          rel="noreferrer"
        >
          {t("ringDetailedView.Read more")}
        </a>
      ) : (
        <></>
      )}
      <div>
        <strong>{t("ringDetailedView.uploaded by")}:</strong>{" "}
        {typeof ring.uploadedBy === "object" && ring.uploadedBy.id ? (
          <div>
            <Link to={`/profile/${ring.uploadedBy.id}`}>
              {ring.uploadedBy.name ? ring.uploadedBy.name : "missing_name"}
            </Link>
            <br />
            {ring.addedOn && (
              <div>
                <span className="ring-detailed-view__date">
                  {new Date(ring.addedOn).toLocaleDateString("en-CA")}
                </span>
                <br />
              </div>
            )}
            <button
              onClick={() => {
                alert(
                  "Functionality incoming! Thanks for pressing, it means this functionality will come sooner",
                );
                logEvent(analytics, "view_promotion", {
                  promotion_name: "message_user",
                });
              }}
            >
              {t("ringDetailedView.message user")}
            </button>
          </div>
        ) : (
          NOT_LOGGED_IN
        )}
      </div>
      <CommentsContainer ringId={ring.id} />
    </div>
  );
};

export default RingInfo;
